import './style.scss';

import React from 'react';

import BaseCarousel from '@widgets/carousels/base/BaseCarousel';
import ContentCarouselButtons from '../shared/content-carousel-buttons/ContentCarouselButtons';
import HeaderContentCarousel from '../shared/header-content-carousel/HeaderContentCarousel';
import CarouselItem from './CarouselItem';
import type { EmblaOptionsType } from 'embla-carousel-react';
import type { ContentCarouselItem } from '@components/models';
import type { ContentCarousel } from '@widgets/models';

const ContentCarouselComponent = ({ id, title, link_details, slug, page_model, ...rest }: ContentCarousel) => {
    const items = rest.items ?? rest.segment_item?.items;

    const OPTIONS: EmblaOptionsType = {
        dragFree: true,
        containScroll: 'trimSnaps',
        slidesToScroll: 3,
    };

    return (
        <BaseCarousel
            renderHeaderNode={<HeaderContentCarousel title={title} link={link_details} />}
            renderNavComponents={ContentCarouselButtons}
            extraClassForContainer={{ classNames: 'content-carousel__container', overrideBaseClass: false }}
            extraClassForViewport={{ classNames: 'content-carousel__viewport', overrideBaseClass: false }}
            options={OPTIONS}
        >
            {items.map((i: ContentCarouselItem, idx: number) => (
                <CarouselItem
                    key={i.id}
                    componentId={id}
                    id={i.id}
                    idx={idx}
                    title={i.title}
                    img={i.img}
                    url_path={i.url_path}
                    slug={slug}
                    page_model={page_model}
                />
            ))}
        </BaseCarousel>
    );
};

export default ContentCarouselComponent;
