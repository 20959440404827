import React from 'react';
import ImageWidget from '@widgets/image-widget/ImageWidget';
import type { ContentCarouselItem } from '@components/models';
import LinkDetails, { LINK_TYPE, LINK_UI } from '@widgets/link-details-widget/LinkDetails';

interface Props extends ContentCarouselItem {
    componentId: number;
    id: number;
    idx: number;
    slug: string;
    page_model: string;
}

const CarouselItem = ({ slug, page_model, componentId, id, url_path, img, idx, title }: Props) => {

    const dataSection = title ?? `${slug} ${page_model} page content carousel ${componentId}`;
    const dataOutcome = title ?? `${slug} ${page_model} page content carousel ${componentId} title`;
    const dataItemValue = title ?? `${slug} ${page_model} page content carousel ${componentId} sub-component ${id}`;

    return (
        <div className="content-carousel-item" data-testid="content-carousel-item">
            <LinkDetails
                linkType={LINK_TYPE.LINK}
                linkUI={LINK_UI.PLACEHOLDER}
                url_path={url_path}
                gaTrackingDetails={{
                    data_section: dataSection,
                    data_outcome: dataOutcome,
                    data_component_item: 'carousel item',
                    data_item_value: dataItemValue,
                }}
                testId={`content-carousel-item__url-${idx}`}
            >
                <div className="content-carousel-item__brand">
                    <ImageWidget
                        alt={`${img.name}`}
                        testId={`content-carousel-item__img-${idx}`}
                        src={img.url}
                    />
                </div>
                <span
                    className="content-carousel-item__title"
                    data-testid={`content-carousel-item__title-${idx}`}
                >
                    {title}
                </span>
            </LinkDetails>
        </div>
    );
};

export default CarouselItem;
